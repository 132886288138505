import { io } from 'socket.io-client';

class SocketService {
    constructor() {
        if (!SocketService.instance) {
            this.socket = null;
            SocketService.instance = this;
        }
        return SocketService.instance;
    }

    connect() {
        if (!this.socket) {
            this.socket = io(process.env.REACT_APP_SOCKET_URL, {
                path: '/socket.io',
                transports: ['websocket'],
                secure: true,
            });

            this.socket.on('connect', () => {
                console.log('WebSocket connected:', this.socket.id);
            });

            this.socket.on('disconnect', (reason) => {
                console.warn('WebSocket disconnected:', reason);
            });

            this.socket.on('error', (error) => {
                console.error('WebSocket error:', error);
            });
        }
        return this.socket;
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
        }
    }

    getSocket() {
        return this.socket;
    }
}

const socketService = new SocketService();
export default socketService;
