import React from 'react';

function Statistics() {
  return (
    <div>
      <h2>Statistiken</h2>
      <p>Anzahl bearbeiteter Einsätze: 12</p>
      <p>Durchschnittliche Reaktionszeit: 5 Minuten</p>
    </div>
  );
}

export default Statistics;
