import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { MapContext } from "./MapContext";
import { io } from 'socket.io-client';

function StationManagement() {
    const [stations, setStations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showNewStationForm, setShowNewStationForm] = useState(false);
    const [newStation, setNewStation] = useState({
        name: '',
        type: 'Rettungsdienst',
        location: { latitude: '', longitude: '' },
    });
    const { setMapCenter } = useContext(MapContext);

    useEffect(() => {
        const socket = io(process.env.REACT_APP_SOCKET_URL, {
            path: '/socket.io',
            transports: ['websocket'],
        });
        axios.get(`${process.env.REACT_APP_API_URL}/stations`)
        .then((response) => {
            setStations(response.data);
            setIsLoading(false);
        })
        .catch((error) => {
            console.error('Fehler beim Abrufen der Wachen:', error);
            setIsLoading(false);
        });

        socket.on('updateStations', (data) => setStations(data));

        return () => socket.disconnect();
    }, []);

    const deleteStation = (id) => {
        if (window.confirm('Möchtest du diese Wache wirklich löschen?')) {
            axios.delete(`${process.env.REACT_APP_API_URL}/stations/${id}`)
            .then(() => {
                setStations(stations.filter((station) => station._id !== id));
                alert('Wache erfolgreich gelöscht.');
            })
            .catch((error) => {
                console.error('Fehler beim Löschen der Wache:', error);
                alert('Fehler beim Löschen der Wache. Bitte versuche es erneut.');
            });
        }
    };

    const viewStation = (id) => {
        window.alert('Hier könnten ihre Wachdetails stehen.');
    };

    const changeMapCenter = (location, zoom) => {
        setMapCenter({ location, zoom }); 
    };

    const handleNewStationSubmit = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/stations`, newStation)
        .then((response) => {
            setShowNewStationForm(false);
            alert('Neue Wache erfolgreich erstellt.');
        })
        .catch((error) => {
            console.error('Fehler beim Erstellen der Wache:', error);
            alert('Fehler beim Erstellen der Wache. Bitte versuche es erneut.');
        });
    };

    return (
        <div>
            {isLoading ? (
                <p>Wird geladen...</p>
            ) : (
                <div className="station-list-container">
                    <div className="content">
                        <div className="tabs">
                            <button onClick={() => setShowNewStationForm(true)}>Neue Wache</button>
                        </div>
                    </div>

                    {showNewStationForm && (
                        <div className="new-station-form">
                            <h3>Neue Wache erstellen</h3>
                            <label>Name:</label>
                            <input type="text" value={newStation.name} onChange={(e) => setNewStation({ ...newStation, name: e.target.value })} />
                            <label>Typ:</label>
                            <input type="text" value={newStation.type} onChange={(e) => setNewStation({ ...newStation, type: e.target.value })} />
                            <label>Breitengrad:</label>
                            <input type="number" step="any" value={newStation.location.latitude} onChange={(e) => setNewStation({ ...newStation, location: { ...newStation.location, latitude: e.target.value } })} />
                            <label>Längengrad:</label>
                            <input type="number" step="any" value={newStation.location.longitude} onChange={(e) => setNewStation({ ...newStation, location: { ...newStation.location, longitude: e.target.value } })} />
                            <button onClick={handleNewStationSubmit}>Erstellen</button>
                            <button onClick={() => setShowNewStationForm(false)}>Abbrechen</button>
                        </div>
                    )}

                    {stations.map((station) => (
                        <div className="station-item" key={station._id} onClick={() => changeMapCenter([station.location.latitude, station.location.longitude], 17)}>
                            <strong>{station.name}</strong>
                            <div className="details">
                                {station.type}
                                <br />
                                Standort: {station.location.latitude}, {station.location.longitude}
                            </div>
                            <button onClick={() => deleteStation(station._id)}>Löschen</button>
                            <button onClick={() => viewStation(station._id)}>Details</button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default StationManagement;